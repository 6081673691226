import { initCategoryBtns } from "./adf/funds"
import { initCheckout } from "./api/processPayment"
import { getCountries } from "./api/getCountries"
// import { getEditorContent } from "./api/getEditorContent"
// import { getPublicKey } from "./api/getPublicKey"
import { getTitles } from "./api/getTitles"

export const partId = document.querySelector('.BBDonationApiContainer') ? document.querySelector('.BBDonationApiContainer').dataset.partid : null
export const donationService = partId ? new BLACKBAUD.api.DonationService(partId) : null

export const init = () => {
  initCategoryBtns()
  getTitles()
  getCountries()
  if (partId) {
    // getEditorContent()
    // getPublicKey()
    initCheckout()
  }
}