"use strict"

import '../sass/styles.scss'

import { Modal } from 'bootstrap'
import dayjs from 'dayjs'
import { elements } from "./variables/elements"
import { state } from "./variables/state"
import { params } from "./variables/params"
import { init as adf } from "./adf"
import { init as matchingGift } from "./adf/matchingGift"
import { init as api } from "./api"
import { getFunds } from "./api/getFunds"
import { init as form } from "./form"
import { init as persistence, clearStorage, restoreForm } from "./form/persistence"
import { appendModals } from "./ui/modal"
import { addPageBgImage } from "./ui/page"
// import { speech } from "./ui/speech";
import { init as ui } from "./ui"

const adfConstants = window.ZU_ADF.constants || {}
const options = window.ZU_ADF.options || {}

export const initForm = () => {
  getFunds()
  adf()
  form()
  ui()
  persistence()
  api()
  // speech();
}

const getOptions = () => {
  if (options.enableAnonymous) {
    document.body.classList.add('anonymous-enabled')
  }
  if (options.enableCorporate) {
    document.body.classList.add('corporate-enabled')
  }
  if (options.enableComments) {
    document.body.classList.add('comments-enabled')
  }
  if (options.enableJoint) {
    document.body.classList.add('joint-enabled')
  }
  if (options.enableMatching) {
    document.body.classList.add('matching-enabled')
  }
  if (options.enableOther) {
    document.body.classList.add('other-enabled')
  }
  if (options.enablePledge) {
    document.body.classList.add('pledge-enabled')
  }
  if (options.enableRecurring) {
    document.body.classList.add('recurring-enabled')
  }
  if (options.enableSearch) {
    document.body.classList.add('search-enabled')
  }
  if (options.enableTribute) {
    document.body.classList.add('tribute-enabled')
  }
  if (options.enableWriteIn) {
    document.body.classList.add('write-in-enabled')
  }
  if (options.enableEmployeeCampaign && adfConstants.employeeCampaignEndDate !== '') {
    const diff = dayjs(adfConstants.employeeCampaignEndDate).diff(dayjs(), 'day')
    if (diff > 0 && diff <= 30) {
      document.body.classList.add('campaign-active')
      state.campaignActive = true
    }
  }
}

const loadForm = () => {
  const formSaved = localStorage.getItem('formSaved')

  if (params.pageBg) {
    addPageBgImage(params.pageBg)
  }
  appendModals()
  if (options.enableQuickForm && params.quickForm === '1') {
    initForm()
  } else {
    getOptions()
    if (options.enableMatching) {
      matchingGift()
    }
    if (elements.modalRestore && formSaved) {
      const modal = new Modal(elements.modalRestore)
      modal.show()
      document.getElementById('modalContinue').addEventListener('click', e => {
        state.restore = true
        restoreForm()
      })
      document.getElementById('modalReset').addEventListener('click', e => {
        state.isReturning = true
        clearStorage()
        initForm()
      })
      state.featuredFundShown = localStorage.getItem('featuredFundShown')
    } else {
      initForm()
    }
  }
}

window.loadForm = loadForm
window.initForm = initForm