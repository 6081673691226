export let appealId = null

export const getAppeals = (appeal) => {
  const queryService = new BLACKBAUD.api.QueryService()

  const onSuccess = (data) => {
    console.log(data)
    // Trying to match URL appeal code to a GUID
    const matchedAppeal = data.Rows.filter(obj => {
      return obj.Values[0].toLowerCase() === appeal.toLowerCase()
    })

    if (matchedAppeal.length > 0) {
      appealId = matchedAppeal[0].Values[1]
    }
  }

  const onFail = (errors) => {
    console.log(errors)
  }

  queryService.getResults(guids.appeals, onSuccess, onFail)
}