import * as dayjs from 'dayjs'
import { classes } from "../variables/classes"
import { constants } from "../variables/constants"
import { elements } from "../variables/elements"
import { getTotalGiftAmount, numToFloat } from "../lib/utils"
import { initCollapse } from '../ui/collapse'

const adfConstants = window.adfConstants || {}
const options = window.ZU_ADF.options || {}

let defaultNumInstallments

export const init = () => {
  updatePayrollStartDate()
  updatePayrollSummary()
}

export const setDefaultNumInstallments = () => {
  defaultNumInstallments = parseInt(getTotalGiftAmount() / constants.minInstallmentAmount)
}

const updatePayrollStartDate = () => {
  let inCampaign = false
  let campaignEndDate = null
  let campaignPledgeProcessDate = null

  if (options.enableEmployeeCampaign) {
    if (dayjs(adfConstants.employeeCampaignEndDate).diff(dayjs()) > 0) {
      inCampaign = true
      campaignEndDate = dayjs(dayjs(adfConstants.employeeCampaignEndDate).add(30, 'day')).format('MM/DD/YYYY')
      campaignPledgeProcessDate = dayjs(adfConstants.employeeCampaignPledgeProcessDate).format('MM/DD/YYYY')
    }
  }
  if (inCampaign) {
    document.querySelectorAll('.js-campaign-end-date').forEach(el => {
      el.textContent = campaignPledgeProcessDate
    })
    document.querySelectorAll('.payroll-summary').forEach(el => {
      el.querySelector('.campaign').classList.remove(classes.hiddenClass)
      el.querySelector('p:not(.campaign)').classList.add(classes.hiddenClass)
    })
  } else {
    document.querySelectorAll('.js-payroll-start-date').forEach(el => {
      el.textContent = dayjs().format('MM/DD/YYYY')
    })
  }
}

const updateBiWeeklySummary = () => {
  const numInstallments = !isNaN(elements.numInstallments.value) ? (Number(elements.numInstallments.value) >= constants.minNumInstallments && Number(elements.numInstallments.value) <= constants.maxNumInstallments ? elements.numInstallments.value : '') : ''
  const installmentAmount = parseFloat(elements.totalAmount.textContent.replace(/\$/, '').replace(/\,/g, '')) / numInstallments

  if (installmentAmount < 2) {
    elements.payrollSummary.classList.add(classes.hiddenClass)
  } else {
    elements.payrollSummary.classList.remove(classes.hiddenClass)
    document.querySelectorAll('.js-installment-amount').forEach(el => {
      el.innerText = `$${Inputmask.format(String(numToFloat(installmentAmount, 2)), { alias: 'currency' })}`
    })
    document.querySelectorAll('.js-installments').forEach(el => {
      el.textContent = numInstallments
    })
  }
}

export const updatePayrollSummary = () => {
  elements.numInstallments.value = Math.min(defaultNumInstallments, constants.maxNumInstallments)
  document.querySelectorAll('.js-installments').forEach(el => {
    el.textContent = defaultNumInstallments
  })
  updateBiWeeklySummary()
  elements.numInstallments.addEventListener('input', updateBiWeeklySummary)
}