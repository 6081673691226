import Fuse from 'fuse.js'
import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { allFunds, resetFunds } from "../adf/funds"
import * as utils from "../lib/utils"

export const resetSearch = () => {
  elements.searchInput.value = ''
  utils.triggerEvent(elements.searchInput, 'keyup')
}

const useOrOperator = (str) => {
  return str.replace(/\s/g, ' | ')
}

const getSearchResults = (fuse) => {
  let len = elements.searchResultsSelect.length

  for (let i = 1; i < len; i++) {
    elements.searchResultsSelect.remove(1)
  }

  elements.searchResults.classList.add(classes.hiddenClass)

  if (elements.searchInput.value.length > 2) {
    const searchTerms = (useOrOperator(elements.searchInput.value)).trim()
    let results = fuse.search(searchTerms)
    let resultsLen = results.length
    let filteredResults = null

    if (resultsLen > 0) {
      elements.noResultsFound.classList.add(classes.hiddenClass)
      elements.searchResults.classList.remove(classes.hiddenClass)
      filteredResults = results.reduce((unique, result) => {
        if (!unique.some(obj => obj.item.guid === result.item.guid)) {
          unique.push(result)
        }
        return unique
      }, [])
      resultsLen = filteredResults.length
      utils.addOptions(elements.searchResultsSelect, filteredResults, resultsLen)
      searchResultsCount.textContent = `We found ${resultsLen} matching funds`
      searchResultsSelect.addEventListener('change', e => {
        resetFunds()
      })
    } else {
      elements.searchResults.classList.add(classes.hiddenClass)
      elements.noResultsFound.classList.remove(classes.hiddenClass)
    }
  } else {
    elements.searchResults.classList.add(classes.hiddenClass)
    elements.noResultsFound.classList.add(classes.hiddenClass)
  }
}

export const fundSearch = () => {
  const options = {
    shouldSort: true,
    threshold: 0.3,
    minMatchCharLength: 3,
    keys: ['name', 'keywords'],
    distance: 10000,
    useExtendedSearch: true
  }
  let fuse = new Fuse(allFunds, options)

  elements.searchInput.addEventListener('keyup', utils.debounce(e => {
    getSearchResults(fuse)
  }, 250))

  elements.searchInput.addEventListener('change', e => {
    getSearchResults(fuse)
  })

  // elements.searchResultsSelect.addEventListener('change', e => {
  //   const redirectUrl = utils.getSelectedOptionRedirect(e.currentTarget)
  //   const selectedFund = utils.getSelectedOptionValue(e.currentTarget);

  //   if (redirectUrl) {
  //     utils.redirect(redirectUrl);
  //   } else {
  //     // maybeGiftLevels(selectedFund);
  //   }
  // })
}