import { elements } from "../variables/elements"
import { createOption } from "../lib/utils"

const codeTableService = typeof BLACKBAUD !== 'undefined' ? new BLACKBAUD.api.CodeTableService() : null

const buildTitleSelect = (titles) => {
  titles.forEach(title => {
    createOption(elements.title, title.Description)
  })
}

export const getTitles = () => {
  const onSuccess = (data) => {
    buildTitleSelect(data)
  }

  const onFail = (errors) => {
    console.log(errors)
  }

  if (codeTableService) {
    // codeTableService.getTitles(guids.titleTable, onSuccess, onFail);
    codeTableService.getEntries(guids.titleTable, onSuccess, onFail)
  }
}