import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { updateSummary } from "./summary"
import { enableTribute, disableTribute, enablePledge, disablePledge } from "./tribute"

const options = window.ZU_ADF.options || {}

export const initRecurrenceBtns = () => {
  // One time gift buttons + payment method buttons
  document.querySelectorAll('input[type=radio][name*=recurrence][value=""], input[type=radio][name=payment-method]').forEach(el => {
    el.addEventListener('click', e => {
      enableTribute(e)
      enablePledge(e)
      updateSummary()
    })
  })
  // Recurring gift buttons
  document.querySelectorAll('input[type=radio][name*=recurrence]:not([value=""])').forEach(el => {
    el.addEventListener('click', e => {
      disableTribute(e)
      disablePledge(e)
      updateSummary()
    })
  })
}

export const getGiftFrequency = () => {
  const defaultRecurrence = elements.giftRecurrence.querySelector('[name="gift-recurrence"]:checked').nextElementSibling.textContent

  if (options.enablePayroll && elements.giftRecurrencePayroll) {
    if (elements.giftRecurrencePayroll.classList.contains(classes.collapseShowClass)) {
      return elements.giftRecurrencePayroll.querySelector('[name="gift-recurrence-payroll"]:checked').nextElementSibling.textContent
    } else {
      return defaultRecurrence
    }
  }
  return defaultRecurrence
}

export const hideRecurrence = () => {
  if (options.enablePayroll && elements.giftRecurrencePayroll) {
    elements.giftRecurrencePayroll.classList.add(classes.hiddenClass)
  }
  elements.giftRecurrence.classList.add(classes.hiddenClass)
}