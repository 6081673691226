import { Modal } from "bootstrap"
import { elements } from "../variables/elements"
import { events } from "../variables/events"
import { params } from "../variables/params"
import { state } from "../variables/state"
import { saveForm } from "../form/persistence"
import { getFund } from "./funds"
import { addPreSelectedGiftRow } from "./preselectFunds"
import { fv } from "../form/validation"

const constants = window.ZU_ADF.constants || {}

export const showFeaturedFund = () => {
  const modalFeaturedFund = new Modal(elements.modalFeaturedFund)
  const featuredFund = getFund(constants.ffLookupId)

  if (featuredFund) {
    state.featuredFundShown = true
    elements.modalFeaturedFund.style.setProperty('--ffBgImg', `url(${constants.ffBgImg.url})`)
    document.getElementById('ffDisplayName').textContent = constants.ffDisplayName !== '' ? constants.ffDisplayName : featuredFund.name
    document.getElementById('ffDescription').textContent = constants.ffDescription
    modalFeaturedFund.show()
    document.getElementById('modalAccept').addEventListener('click', e => {
      let gift = {}

      gift.fund = featuredFund
      gift.amount = constants.ffDefaultAmount !== '' ? Number(constants.ffDefaultAmount) : 0
      gift.edit = ''
      gift.remove = ''
      addPreSelectedGiftRow(gift)
      saveForm(1)
      fv.plugins.wizard.currentStep = fv.plugins.wizard.currentStep - 1
    })
    document.getElementById('modalReject').addEventListener('click', e => {
      if (!params.quickForm) {
        fv.plugins.wizard.currentStep = 2
        events.panelValid.index = 2
        window.dispatchEvent(events.panelValid)
      }
    })
  }
}