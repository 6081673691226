export const classes = {
  activeClass: 'active',
  collapsedClass: 'collapse',
  collapseShowClass: 'show',
  collapsingClass: 'collapsing',
  completeClass: 'complete',
  hasBackgroundImage: 'has-background-image',
  hiddenClass: 'd-none',
  loadingClass: 'loading',
  payrollForm: 'payroll-form',
  quickForm: 'quick-form',
}