import { elements } from "../variables/elements"
import { events } from "../variables/events"
import { state } from "../variables/state"
import { resetAmount } from "./amount"
import { restoreFund } from "./funds"
import { hideGiftLevels } from "./giftLevels"
import { setDefaultNumInstallments, updatePayrollSummary } from "./payroll"
import { resetSearch } from "./search"
import { updateSummary } from "./summary"
import * as funds from "../adf/funds"
import { applyCurrencyMask, setMaskedValue } from "../ui/inputMask"
import { toggleCancelButton } from "../ui/nav"
import * as utils from "../lib/utils"
import { fv, addAmountValidator, removeValidator } from "../form/validation"

const options = window.ZU_ADF.options || {}

export let giftRowIndex = 0

export const getGiftIndex = (gift) => {
  return gift.getAttribute("data-row-index")
}

const editGift = (e) => {
  state.isEditingGift = true
  state.isAnotherGift = false
  restoreFund(e.currentTarget)
  toggleCancelButton()
  window.dispatchEvent(events.goToFirstPanel)
}

const removeGift = (e) => {
  const rowIndex = utils
    .getClosestEl(e.currentTarget, ".gift")
    .getAttribute("data-row-index")
  let giftLists = null
  let gifts = null

  const removeRow = new Promise((resolve, reject) => {
    document
      .querySelectorAll(`[data-row-index="${rowIndex}"]`)
      .forEach((el) => {
        removeValidator(el.querySelector("[name^=amount]"))
        el.remove()
      })
    resolve()
  })

  removeRow.then(() => {
    gifts = document.querySelectorAll(".gift")
    if (gifts.length > 0) {
      giftLists = document.querySelectorAll(".gifts")
      giftRowIndex = gifts.length / giftLists.length
      giftLists.forEach((el) => {
        gifts = el.querySelectorAll(".gift")
        gifts.forEach((el, index) => {
          el.setAttribute("data-row-index", index + 1)
        })
      })
      updateGiftAmountTotal()
      //setRecurrenceFields()
      updateSummary()
    } else {
      giftRowIndex = 0
      funds.resetCategories()
      funds.resetFunds()
      if (options.enableSearch) {
        resetSearch()
      }
      if (options.enableGiftLevels) {
        hideGiftLevels()
      }
      resetAmount()
      events.goToFirstPanel.resetNav = true
      window.dispatchEvent(events.goToFirstPanel)
    }
  })
}

export const initAddNewGift = () => {
  document.querySelectorAll(".btn-add").forEach((el) => {
    el.addEventListener("click", (e) => {
      state.isEditingGift = false
      state.isAnotherGift = true
      toggleCancelButton()
      window.dispatchEvent(events.goToFirstPanel)
    })
  })
}

const updateGiftAmounts = (value, index) => {
  document
    .querySelectorAll(`.gift[data-row-index="${index}"] [name^=amount]`)
    .forEach((el) => {
      el.value = value
      // setMaskedValue(el, value);
    })
}

export const updateGiftAmountTotal = () => {
  let giftAmountTotal = 0

  elements.giftReview.querySelectorAll(".gift").forEach((el) => {
    giftAmountTotal += Number(
      utils.stripCommas(el.querySelector("[name^=amount]").value)
    )
  })

  document.querySelectorAll(".js-total").forEach((el) => {
    el.innerText = `$${Inputmask.format(
      String(utils.numToFloat(giftAmountTotal, 2)),
      { alias: "currency" }
    )}`
  })
  if (options.enablePayroll) {
    setDefaultNumInstallments()
    updatePayrollSummary()
  }
}

export const bindGiftEvents = () => {
  document.querySelectorAll(".btn-edit").forEach((el) => {
    el.removeEventListener("click", editGift)
    el.addEventListener("click", editGift)
  })

  document.querySelectorAll(".btn-remove").forEach((el) => {
    el.removeEventListener("click", removeGift)
    el.addEventListener("click", removeGift)
  })

  document.querySelectorAll(".gift [name^=amount]").forEach((el) => {
    el.addEventListener("change", (e) => {
      updateGiftAmounts(
        e.currentTarget.value,
        utils.getClosestEl(e.currentTarget, ".gift").dataset.rowIndex
      )
      updateGiftAmountTotal()
      fv.revalidateField("numInstallments")
    })
  })
}

const createGift = (el, gift, index) => {
  el.querySelector(".gift-name").innerText = gift.isSearchResult
    ? utils.getSelectedOptionText(gift.searchResult)
    : gift.isSpecifiedFund
      ? elements.writeInFundInput.value
      : utils.getSelectedOptionText(gift.giftFund)
  el.querySelector(".form-control").value = utils.numToFloat(
    gift.giftAmount,
    2
  )
  el.querySelector(".form-control").name = `amount-${giftRowIndex}-${index}`
  el.querySelector(".gift-category").value =
    document.querySelector(".category.active").textContent
  el.querySelector(".gift-category").name = `gift-${giftRowIndex}-${index}`
  el.querySelector(".gift-unit").value =
    gift.isSpecifiedFund || gift.isSearchResult
      ? ""
      : gift.giftUnit
        ? utils.getSelectedOptionValue(gift.giftUnit)
        : ""
  el.querySelector(".gift-unit").name = `gift-unit-${giftRowIndex}-${index}`
  el.querySelector(".gift-area").value =
    gift.isSpecifiedFund || gift.isSearchResult
      ? ""
      : gift.giftArea
        ? utils.getSelectedOptionValue(gift.giftArea)
        : ""
  el.querySelector(".gift-area").name = `gift-area-${giftRowIndex}`
  el.querySelector(".gift-fund").value = gift.isSearchResult
    ? utils.getSelectedOptionValue(gift.searchResult)
    : gift.isSpecifiedFund
      ? guids.defaultFund
      : gift.giftFund
        ? utils.getSelectedOptionValue(gift.giftFund)
        : ""
  el.querySelector(".gift-fund").name = `gift-fund-${giftRowIndex}-${index}`
  el.querySelector(".fund-search").value =
    gift.isSearchResult && !gift.isSpecifiedFund ? true : false
  el.querySelector(
    ".fund-search"
  ).name = `fund-search-${giftRowIndex}-${index}`
  el.querySelector(".fund-specified").value = gift.isSpecifiedFund
    ? true
    : false
  el.querySelector(
    ".fund-specified"
  ).name = `fund-specified-${giftRowIndex}-${index}`
  return el
}

const getGiftAmount = () => {
  const selectedAmount = document.querySelector('[name="gift-amount"]:checked')
  let giftAmount = null

  if (selectedAmount) {
    giftAmount = selectedAmount.value
  } else if (options.enableOther) {
    giftAmount = elements.otherAmount.value
  }
  return Number(giftAmount.replace(/\,/g, ""))
}

const getGift = () => {
  const category = document.querySelector(".funds .tab-pane.active")

  return {
    searchResult: options.enableSearch ? elements.searchResultsSelect : null,
    isSearchResult: options.enableSearch
      ? utils.getSelectedOptionValue(elements.searchResultsSelect) !== ""
      : false,
    isSpecifiedFund: options.enableWriteIn
      ? elements.isWriteInFund.checked && elements.writeInFundInput !== ""
      : false,
    giftUnit: category.querySelector(".unit-select")
      ? category.querySelector(".unit-select")
      : null,
    giftArea: category.querySelector(".area-select")
      ? category.querySelector(".area-select")
      : null,
    giftFund: category.querySelector(".fund-select")
      ? category.querySelector(".fund-select")
      : null,
    giftAmount: getGiftAmount(),
  }
}

export const addGift = () => {
  const gift = getGift()

  giftRowIndex++
  document.querySelectorAll(".gifts").forEach((el, index) => {
    let clone = document
      .getElementById("giftRowTemplate")
      .content.cloneNode(true)

    clone.firstElementChild.setAttribute("data-row-index", giftRowIndex)
    clone = createGift(clone, gift, index)
    addAmountValidator(clone.querySelector("[name^=amount]"))
    el.querySelector(".list-group").append(clone)
    // Dynamic validation for amount field
    addAmountValidator(`amount-${giftRowIndex}-${index}`)
    // Add input mask to amount field
    applyCurrencyMask(
      document.querySelector(`[name="amount-${giftRowIndex}-${index}"]`)
    )
  })
  bindGiftEvents()
  updateGiftAmountTotal()
  updateSummary()
}

export const updateGift = () => {
  const gift = getGift()
  const giftRows = document.querySelectorAll(
    '[data-row-index="' + state.giftIndex + '"]'
  )

  if (giftRows.length > 0) {
    giftRows.forEach((el, index) => {
      el = createGift(el, gift, index)
    })
    updateGiftAmountTotal()
    //setRecurrenceFields()
    updateSummary()
  }
}

export const updateGiftRowIndex = () => {
  return giftRowIndex++
}
