import { initAmount } from "./adf/amount"
import { initAddNewGift } from "./adf/gifts"
import { initRecurrenceBtns } from "./adf/giftRecurrence"
import { init as payroll } from "./adf/payroll"
import { init as writeInFund } from "./adf/writeInFund"

const options = window.ZU_ADF.options || {}

export const init = () => {
  initAmount()
  if (options.enableTribute && options.enableRecurring) {
    initRecurrenceBtns()
  }
  if (options.enablePayroll) {
    payroll()
  }
  if (options.enableWriteIn) {
    writeInFund()
  }
  initAddNewGift()
}