import { classes } from "../variables/classes"
import { constants } from "../variables/constants"
import { elements } from "../variables/elements"

const options = window.ZU_ADF.options || {}

export const initAmount = () => {
  if (options.enableOther) {
    initOtherAmount()
  }
}

const maybeDeactivateRadios = () => {
  if (elements.otherAmount.value !== '') {
    elements.amountBtns.forEach(el => {
      el.checked = false
    })
  }
}

const activateOtherAmount = () => {
  elements.otherAmount.closest('[class*=col]').classList.add(classes.activeClass)
  elements.amountBtns.forEach(el => {
    el.checked = false
  })

  // maybeDeactivateRadios();
}

const deactivateOtherAmount = () => {
  elements.otherAmount.value = ''
  elements.otherAmount.closest('[class*=col]').classList.remove(classes.activeClass)
}

const initOtherAmount = () => {
  elements.otherAmount.addEventListener('change', activateOtherAmount)
  elements.otherAmount.addEventListener('focus', activateOtherAmount)
  // elements.otherAmount.addEventListener('input', maybeDeactivateRadios);
  // elements.otherAmount.addEventListener('blur', maybeDeactivateRadios);
  elements.amountBtns.forEach(el => {
    el.addEventListener('click', deactivateOtherAmount)
  })
}

export const resetAmount = () => {
  const checkedAmount = document.querySelector('[name="gift-amount"]:checked')
  const defaultAmount = constants.defaultGiftAmount ? document.querySelector(`[name="gift-amount"][value="${constants.defaultGiftAmount}"]`) : null

  elements.otherAmount.value = ''

  if (checkedAmount) {
    checkedAmount.checked = false
  }
  if (defaultAmount) {
    defaultAmount.click()
  }
}

const matchedAmount = (amount) => {
  const matchedAmount = document.querySelector(`[name="gift-amount"][value="${amount}"]`)

  if (matchedAmount) {
    matchedAmount.click()
    return true
  }
}

export const restoreAmount = (gift) => {
  let amount = gift.querySelector('[name^=amount]').value

  amount = String(Number.isInteger(Number(amount)) ? parseInt(amount) : amount)
  if (matchedAmount(amount)) {
    return
  } else {
    elements.otherAmount.value = amount
  }
}