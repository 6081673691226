export const init = () => {
  const countCharacters = (el) => {
    const container = el.dataset.counter ? document.getElementById(el.dataset.counter) : null
    const maxlength = el.getAttribute('maxlength')
    let charactersLeft = maxlength - el.value.length

    if (container) {
      if (charactersLeft >= 0) {
        container.textContent = charactersLeft
      } else {
        container.textContent = '0'
        el.value = el.value.substr(0, maxlength)
      }
    }
  }

  document.querySelectorAll('[maxlength]').forEach(el => {
    el.addEventListener('input', e => {
      countCharacters(e.currentTarget)
    })
  })
};