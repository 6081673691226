import AutoNumeric from "autonumeric"
import Inputmask from "inputmask"
import { elements } from "../variables/elements"

const options = window.ZU_ADF.options || {}

export const init = () => {
  // applyPhoneMask()
  if (options.enablePayroll) {
    applyEmployeeIdMask()
    allowLeadingZeros()
  }
  if (options.enableOther) {
    applyCurrencyMask(elements.otherAmount)
  }
}

const applyPhoneMask = () => {
  new AutoNumeric(elements.phone, {
    decimalPlaces: 0,
    digitGroupSeparator: '',
    minimumValue: '0',
    modifyValueOnWheel: false,
    onInvalidPaste: 'ignore',
  })
}

export const applyCurrencyMask = (el) => {
  new AutoNumeric(el, {
    decimalPlaces: 2,
    maximumValue: "1000000",
    minimumValue: "0",
    modifyValueOnWheel: false,
    onInvalidPaste: "ignore",
    watchExternalChanges: true, //!!!
  })
}

const applyEmployeeIdMask = () => {
  new AutoNumeric(elements.employeeId, {
    allowDecimalPadding: false,
    decimalPlaces: 0,
    decimalPlacesOverride: 0,
    digitGroupSeparator: "",
    leadingZero: "keep",
    maximumValue: "9999999",
    minimumValue: "0",
    modifyValueOnWheel: false,
    onInvalidPaste: "ignore",
  })
}

const allowLeadingZeros = () => {
  const maxLength = parseInt(elements.employeeId.getAttribute("maxlength"))

  // If you use leadingZero: "keep" and go beyond the max length of numbers, leading zero(s) will be removed
  elements.employeeId.addEventListener("keydown", (e) => {
    if (e.target.value.length >= maxLength) {
      e.preventDefault()
      return false
    }
  })
}

export const setMaskedValue = (el, value) => {
  AutoNumeric.set(el, value)
}
