import { classes } from "../variables/classes"
import { elements } from "../variables/elements"

export const addPageBgImage = (index) => {
  if (typeof adfBackgrounds !== 'undefined' && !isNaN(parseInt(index))) {
    if (typeof adfBackgrounds[parseInt(index)] !== 'undefined') {
      elements.main.classList.add(classes.hasBackgroundImage)
      elements.main.style.setProperty('--pageBgImg', `url(${adfBackgrounds[parseInt(index)].image})`)
    }
  }
}

const goToTop = () => {
  window.scrollTo(0, 0)
}

export const hidePage = () => {
  elements.page.classList.add(classes.loadingClass)
}

export const showPage = () => {
  goToTop()
  setTimeout(() => {
    elements.page.classList.remove(classes.loadingClass)
  }, 500)
}