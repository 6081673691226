import { constants } from "../variables/constants"
import { elements } from "../variables/elements"
import { events } from "../variables/events"
import { goToNextPanel } from "../ui/nav"
import { selectors } from "../variables/selectors"
import { state } from "../variables/state"
import { params } from "../variables/params"
import { saveForm } from "./persistence"
import { resetAmount } from "../adf/amount"
import { showFeaturedFund } from "../adf/featuredFund"
import { resetCategories, resetFunds } from "../adf/funds"
import { hideGiftLevels } from "../adf/giftLevels"
import { addGift, updateGift } from "../adf/gifts"
import {
  togglePreSelectedFundOnly,
  addPreselectedGift,
  getPreSelectedData,
} from "../adf/preselectFunds"
import { resetSearch } from "../adf/search"
import { resetWriteInFund } from "../adf/writeInFund"
import { processDonation } from "../api/createDonation"
import {
  getSelectedAmount,
  getGiftAmount,
  getTotalGiftAmount,
  addCommas,
  stripCommas,
} from "../lib/utils"

// Use the algorithms
import luhn from "formvalidation/algorithms/luhn"

// Use the validators
import callback from "formvalidation/validators/callback"
import greaterThan from "formvalidation/validators/greaterThan"
import lessThan from "formvalidation/validators/lessThan"
import notEmpty from "formvalidation/validators/notEmpty"
import regexp from "formvalidation/validators/regexp"
import stringLength from "formvalidation/validators/stringLength"

// Use the core library
import formValidation from "formvalidation/core/Core"

// Use the plugins
import Aria from "formvalidation/plugins/Aria"
import Bootstrap5 from "formvalidation/plugins/Bootstrap5"
import Excluded from "formvalidation/plugins/Excluded"
import Message from "formvalidation/plugins/Message"
import Transformer from "formvalidation/plugins/Transformer"
import Trigger from "formvalidation/plugins/Trigger"
import Wizard from "formvalidation/plugins/Wizard"

const amountMessage = "Gift amount required"
const amountMinMessage = `Online donations must be $${constants.MIN_GIFT_AMOUNT}.00 or greater.`
const amountMaxMessage = `For security reasons, online donations must be less than $${addCommas(
  constants.MAX_GIFT_AMOUNT
)}.00. Please call 800-768-9996 to make a credit card gift over the phone.`
const installmentsMinMessage = "Number of installments must be greater than 1."
const installmentsMaxMessage = "Number of installments must be less than 25."
const installmentPledgeMessage =
  "Installment amount must be greater than or equal to $2. Please increase the gift amount or reduce the number of installments."

const options = window.ZU_ADF.options || {}

export let fv = null

export const init = () => {
  if (params.quickForm) {
    initFvOneStep()
  } else {
    initFvMultiStep()
    handlePanelValid()
  }
  initFundInput()
}

export const addAmountValidator = (fieldName) => {
  fv.addField(fieldName, {
    validators: {
      notEmpty: {
        message: "Required",
      },
      greaterThan: {
        inclusive: true,
        message: amountMinMessage,
        min: constants.MIN_GIFT_AMOUNT,
      },
      lessThan: {
        inclusive: true,
        message: amountMaxMessage,
        max: constants.MAX_GIFT_AMOUNT,
      },
    },
  })
}

export const disableValidator = (fieldName, validator = null) => {
  if (validator) {
    fv.disableValidator(fieldName, validator)
  } else {
    fv.disableValidator(fieldName)
  }
}

export const enableValidator = (fieldName, validator = null) => {
  if (validator) {
    fv.enableValidator(fieldName, validator)
  } else {
    fv.enableValidator(fieldName)
  }
  fv.revalidateField(fieldName)
}

export const removeValidator = (field) => {
  const fieldName = typeof field === "string" ? field : field.name

  fv.removeField(fieldName)
}

const getFields = () => {
  let fields = {}

  // Build 'fields' object for formValidation
  document
    .querySelectorAll(
      ':required:not([name="gift-amount"]):not(#otherAmount):not(#email):not(#phone):not(#zip):not(#employeeId):not(#employeeSignature):not(.optional):not(.verify)'
    )
    .forEach((el) => {
      fields[el.name] = {
        validators: {
          notEmpty: {
            message: "Required",
          },
        },
      }
    })
  // Add gift amount validator
  fields["gift-amount"] = {
    validators: {
      callback: {
        message: amountMessage,
        callback: (input) => {
          if (options.enableOther) {
            if (elements.otherAmount.value !== "") {
              return true
            } else {
              return getSelectedAmount()
            }
          } else {
            return false
          }
        },
      },
    },
  }
  // Add other gift amount validator
  if (options.enableOther) {
    fields["otherAmount"] = {
      validators: {
        callback: {
          message: amountMessage,
          callback: (input) => {
            if (input.value !== "") {
              return true
            } else {
              return getSelectedAmount()
            }
          },
        },
        greaterThan: {
          inclusive: true,
          message: amountMinMessage,
          min: constants.MIN_GIFT_AMOUNT,
        },
        lessThan: {
          inclusive: true,
          message: amountMaxMessage,
          max: constants.MAX_GIFT_AMOUNT,
        },
      },
    }
  }
  // Add email validator
  fields["email"] = {
    validators: {
      notEmpty: {
        message: "Required",
      },
      regexp: {
        regexp: /^[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/i,
        message: "Please enter a valid email address",
      },
    },
  }
  // Add phone validator
  fields["phone"] = {
    validators: {
      notEmpty: {
        message: "Required",
      },
      regexp: {
        regexp: /^[0-9\s-]*$/i,
        message: "Please enter a valid phone number",
      },
    },
  }
  // Add zip code validator
  fields["zip"] = {
    validators: {
      notEmpty: {
        message: "Required",
      },
      regexp: {
        regexp: /^(a-z|A-Z|0-9)*[^~`!.,@\?<>:\'\"_#$%^&*()\//']*$/i,
        message: "Please enter a valid zip/postal code",
      },
    },
  }
  // Add payroll ID validator
  if (options.enablePayroll) {
    fields["employeeId"] = {
      validators: {
        notEmpty: {
          message: "Required",
        },
        regexp: {
          regexp: /^\d{7}$/,
          message: "Employee ID must be 7 characters",
        },
      },
    }
    // Add payroll employee signature validator
    fields["employeeSignature"] = {
      validators: {
        notEmpty: {
          message: "Required",
        },
        regexp: {
          regexp: /^[a-z ,.'-]+$/i,
          message: "Full name cannot contain numbers or special characters",
        },
      },
    }
  }
  // Add payroll ID validator
  if (options.enablePledge) {
    fields["numInstallments"] = {
      validators: {
        notEmpty: {
          message: "Required",
        },
        greaterThan: {
          inclusive: true,
          message: installmentsMinMessage,
          min: 2,
        },
        lessThan: {
          inclusive: true,
          message: installmentsMaxMessage,
          max: 25,
        },
        callback: {
          message: installmentPledgeMessage,
          callback: (input) => {
            const installmentAmount = !isNaN(input.value)
              ? getTotalGiftAmount() / input.value
              : 0

            if (installmentAmount < 2) {
              return false
            }
            return true
          },
        },
      },
    }
  }
  return fields
}

const getTransforms = () => {
  const giftsLength = document.querySelectorAll(".gifts").length
  const removeCommas = {
    greaterThan: (field, element, validator) => {
      return stripCommas(element.value)
    },
    lessThan: (field, element, validator) => {
      return stripCommas(element.value)
    },
  }
  let transforms = {}

  document
    .querySelectorAll(
      "input[type=email]:required, input[type=tel]:required, input[type=text]:required"
    )
    .forEach((el) => {
      transforms[el.name] = {
        // Trim value before validating
        notEmpty: (field, element, validator) => {
          const value = element.value
          return value.trim()
        },
      }
    })
  // Existing currency fields
  document.querySelectorAll("[inputmode=decimal]").forEach((el) => {
    transforms[el.name] = removeCommas
  })
  // Dynamic currency fields in the gift review sections
  // Cannot add transforms dynamically
  for (let i = 0; i < giftsLength; i++) {
    // Assuming max of 10 gifts so transform can be added to gift review amount inputs
    for (let j = 1; j < 11; j++) {
      transforms[`amount-${j}-${i}`] = removeCommas
    }
  }
  return transforms
}

const getPlugins = () => {
  let plugins = {
    aria: new Aria(),
    excluded: new Excluded(),
    bootstrap: new Bootstrap5(),
    message: new Message({
      container: ".row",
    }),
    transformer: new Transformer(getTransforms()),
    trigger: new Trigger({
      // Allow user to type a bit before validating
      delay: {
        emailAddress: 1,
        phoneNumber: 1,
        employeeId: 1,
      },
    }),
  }
  // Add wizard plugin if not a quick form
  if (!params.quickForm) {
    plugins.wizard = new Wizard({
      stepSelector: selectors.panelSelector,
      prevButton: selectors.prevButton,
      nextButton: selectors.nextButton,
    })
  }
  return plugins
}

const formatError = (e) => {
  const isOtherAmount = e.element === elements.otherAmount
  const row = e.element.closest(".row")
  const invalidFeedback = isOtherAmount ? elements.otherAmount.nextElementSibling : (row ? row.querySelector(".invalid-feedback") : null)
  const note = e.element.parentElement.querySelector(".note")
  const giftRow = e.element.closest(".gift-row")

  if (invalidFeedback) {
    if ((e.element.type === "radio" || isOtherAmount) && row) {
      row.append(invalidFeedback)
    } else if (note) {
      e.element.parentElement.insertBefore(invalidFeedback, note)
    } else if (giftRow) {
      giftRow.append(invalidFeedback)
    }
  }
}

const scrollToError = () => {
  let firstInvalid = document.querySelector('.fv-plugins-bootstrap5-row-invalid')

  if (firstInvalid) {
    firstInvalid.scrollIntoView({ behavior: "smooth" })
  }
}

const toggleFundValidation = (mode) => {
  document
    .querySelectorAll(".unit-select, .area-select, .fund-select")
    .forEach((el) => {
      if (mode === "disable") {
        fv.disableValidator(el.name)
      } else if (mode === "enable") {
        fv.enableValidator(el.name)
      }
    })
}

const handleFundInput = (e) => {
  if (e.currentTarget.value !== "") {
    toggleFundValidation("disable")
  } else {
    toggleFundValidation("enable")
  }
}

const initFundInput = () => {
  if (options.enableSearch) {
    elements.searchResultsSelect.addEventListener("change", handleFundInput)
  }
  if (options.enableWriteIn) {
    elements.writeInFundInput.addEventListener("input", handleFundInput)
  }
}

const handlePanelValid = () => {
  window.addEventListener("panelValid", goToNextPanel)
}

const initFvMultiStep = () => {
  fv = formValidation(document.getElementById("adf"), {
    fields: getFields(),
    plugins: getPlugins(),
  })
    .on("core.element.validated", (e) => {
      formatError(e)
    })
    .on("plugins.wizard.step.active", (e) => {
      // Nothing here
    })
    .on("plugins.wizard.step.invalid", (e) => {
      scrollToError()
    })
    .on("plugins.wizard.step.valid", (e) => {
      // Add gift if on first panel
      if (e.step === 1) {
        if (state.isEditingGift) {
          updateGift()
        } else if (state.preselectedFundOnly) {
          const fund = getPreSelectedData()
          const amount = getGiftAmount()

          if (fund && amount) {
            addPreselectedGift(
              fund.lookupId,
              amount,
              fund.editStatus,
              fund.removeStatus
            )
            state.preselectedFundOnly = false
            togglePreSelectedFundOnly("hide")
          }
        } else {
          addGift()
        }
        resetCategories()
        resetFunds()
        if (options.enableSearch) {
          resetSearch()
        }
        if (options.enableWriteIn) {
          resetWriteInFund()
        }
        if (options.enableGiftLevels) {
          hideGiftLevels()
        }
        resetAmount()
      }
      // Save form data
      saveForm(e.step)
      if (
        e.step === 2 &&
        options.enableFeaturedFund &&
        params.featuredFund &&
        !state.featuredFundShown
      ) {
        showFeaturedFund()
      } else if (elements.panels[e.step]) {
        events.panelValid.numSteps = e.numSteps
        events.panelValid.index = e.step
        window.dispatchEvent(events.panelValid)
      }
    })
    .on("plugins.wizard.valid", (e) => {
      processDonation()
    })
}

const initFvOneStep = () => {
  fv = formValidation(elements.adf, {
    fields: getFields(),
    plugins: getPlugins(),
  })
    .on("core.element.validated", (e) => {
      formatError(e)
    })
    .on("core.form.invalid", (e) => {
      scrollToError()
    })
    .on("core.form.valid", (e) => {
      processDonation()
    })

  elements.btnNext.addEventListener("click", (e) => {
    fv.validate()
  })
}
