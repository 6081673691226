import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { state } from "../variables/state"
import { params } from "../variables/params"
import { hideRecurrence } from "../adf/giftRecurrence"
import { goToPanel } from "../ui/nav"

// import { addPreSelectedDonation } from "./funds";

const showTributeFields = () => {
  elements.isTribute.click()
}

const getTributeFund = (tribute) => {
  return tribute.Values[2] !== '' ? tribute.Values[2] !== '' : guids.defaultFund
}

const setTributeType = (tribute) => {
  const tributeType = tribute.Values[3]
  let tributeBtn

  if (tributeType !== '') {
    tributeBtn = document.querySelector(`[name="tribute-type"][value="${tributeType}"]`)

    if (tributeBtn) {
      tributeBtn.click()
    }
  }
}

const showTributeName = (tribute) => {
  const tributeName = document.getElementById('tributeName')
  const tributeText = tribute.Values[1]

  if (tributeText !== '') {
    document.getElementById('honoreeName').classList.add(classes.hiddenClass)
    tributeName.querySelector('.form-control').textContent = tributeText
    tributeName.classList.remove(classes.hiddenClass)
  }
}

const addTribute = (tribute) => {
  const defaultAmount = ' '
  const editStatus = '1'
  const removeStatus = '1'
  const tributeFund = getTributeFund(tribute)

  // addPreSelectedDonation(tributeFund, defaultAmount, editStatus, removeStatus);
  hideRecurrence()
  showTributeFields()
  setTributeType(tribute)
  showTributeName(tribute)

  goToPanel(1)
  console.log(tribute)
}

export const disableTribute = (e) => {
  if (elements.isTribute.checked && !elements.isTribute.disabled) {
    elements.isTribute.click()
  }
  elements.isTribute.disabled = true
}

export const enableTribute = (e) => {
  elements.isTribute.disabled = false
}

export const disablePledge = (e) => {
  if (elements.isPledge.checked && !elements.isPledge.disabled) {
    elements.isPledge.click()
  }
  elements.isPledge.disabled = true
}

export const enablePledge = (e) => {
  elements.isPledge.disabled = false
}

const findTribute = (tributeId, tributes) => {
  return tributes.filter(obj => {
    return obj.Values[0] === tributeId
  })
}

export const getTributes = (tributeId) => {
  const queryService = new BLACKBAUD.api.QueryService()

  const successCallback = (data) => {
    console.log(data)
    const tribute = findTribute(tributeId, data.Rows)

    if (tribute[0]) {
      state.isTribute = true
      addTribute(tribute[0])
    }
  }

  const failureCallback = (errors) => {
    console.log(errors)
  }

  if (queryService) {
    queryService.getResults(guids.tribute, successCallback, failureCallback)
  }
}

export const maybeTribute = () => {
  if (params.tribute && !state.isReturning) {
    getTributes(params.tribute)
  }
}