import { classes } from "../variables/classes"
import { elements } from "../variables/elements"

export const init = () => {
  document.body.classList.add(classes.quickForm)
  document.querySelectorAll('.panel').forEach(el => {
    el.classList.remove('fade')
    el.classList.add(classes.activeClass)
  })
  elements.btnNext.textContent = 'Make Payment'
}