import { donationService } from '../api'
import { showConfirmation } from "./showConfirmation"
import { donation } from "./createDonation"
import { hidePage, showPage } from "../ui/page"

export const processPledge = () => {
  const onSuccess = (data) => {
    const onSuccess = (data) => {
      showConfirmation(data)
    }

    const onFail = (errors) => {
      console.log(errors)
      showPage()
    }

    donationService.getDonationConfirmationHtml(data.Donation.Id, onSuccess, onFail)
  }

  const onFail = (errors) => {
    console.log(errors)
    showPage()
  }

  hidePage()
  donationService.createDonation(donation, onSuccess, onFail)
}